<template>
    <div class="h5-container">
        <BACKH5 :title="$t('Assets.Deposit')" />
        <div class="main">
            <div class="box1">
                <div class="info-wrap">
                    <el-form ref="form" :model="form" :rules="rules">
                        <div class="info-item">
                            <div class="label">{{$t('Assets.SelectCurrency')}}</div>
                            <el-select v-model="form.user_credit_type" placeholder="" @change="change">
                                <el-option v-for="(item,index) in rechargeCoins" :key="index" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.Address')}}</div>
                            <div class="value">
                                <div class="address">{{address}}</div>
                                <div class="copy" @click="copy(address)">{{$t('Common.copy')}}</div>
                            </div>
                        </div>
                        <qrcode class="ewm-img" :value="address" :size="180" />
                        <div class="info-item">
                            <div class="label">{{$t('Assets.DepositAmount')}}</div>
                            <el-form-item prop="number">
                                <el-input v-model="form.number" type="number" class="form-input" :placeholder="$t('Assets.EnterDepositAmount')"></el-input>
                            </el-form-item>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('Assets.PaymentVoucher')}}</div>
                            <el-upload class="upload-box" :action="action" :headers="headers" :limit="1" :show-file-list="false" :on-success="handleUpload">
                                <div v-if="!form.image" class="upload">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                                <img v-else class="upload-img" :src="form.image" />
                            </el-upload>
                        </div>
                        <el-button :loading="loading" class="confirm-btn" @click="recharge">{{$t('Common.Confirm')}}</el-button>
                        <div class="notice">
                            <div class="label">{{$t('Assets.Notice')}}</div>
                            <div v-html="notice" class="content"></div>
                        </div>
                    </el-form>
                </div>
            </div>
            <div class="box2">
                <div class="title">{{$t('Assets.DepositRecord')}}</div>
                <el-table :data="recordList" style="width: 100%;">
                    <el-table-column prop="created_at" :label="$t('Assets.Time')">
                    </el-table-column>
                    <!-- <el-table-column label="账单类型">
                        <template slot-scope="scope">
                            <span>存款</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="id" :label="$t('Assets.OrderNumber')">
                    </el-table-column>
                    <el-table-column prop="user_credit_type" :label="$t('Assets.Currency')">
                    </el-table-column>
                    <el-table-column :label="$t('Assets.Quantity')">
                        <template slot-scope="scope">
                            <span>{{parseFloat(scope.row.num)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('Assets.Status')">
                        <template slot-scope="scope">
                            <span :style="{color: curStatus(scope.row.status).color}">{{curStatus(scope.row.status).name}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="recordList.length > 0" class="pagination-box">
                    <el-pagination small @current-change="handleCurrentChange"
                        :current-page="currentPage" :pager-count="5"
                        :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                        :page-size="pageSize" layout="prev, pager, next"
                        :total="total" background>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        upload_url
    } from '@/request/api.js'
    export default {
        components: {
            BACKH5: () => import('@/components/BackH5.vue'),
            qrcode: () => import('@/components/qrcode.vue')
        },
        data() {
            return {
                form: {
                    user_credit_type: 'BTC',
                    number: '',
                    image: ''
                },
                rules: {
                    number: [
                        {
                            required: true,
                            message: this.$t('Assets.EnterDepositAmount'),
                            trigger: 'blur'
                        }
                    ]
                },
                action: upload_url,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
                    lang: localStorage.getItem('lang') || 'en'
                },
                loading: false,
                address: '',
                notice: '',
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1
            }
        },
        created() {
            let coin_name = this.$route.query.coin_name
            if(coin_name) {
                this.form.user_credit_type = coin_name
            }
            this.getAddress()
            this.getNotice()
            this.getRecordList(true)
        },
        computed: {
            rechargeCoins() {
                return this.$store.state.rechargeCoins
            },
            
            curStatus() {
                return (status) => {
                    let obj = {
                        color: '',
                        name: ''
                    }
                    switch(status) {
                        case 0:
                            obj = {
                                color: '#08B5E0',
                                name: this.$t('Assets.Status1')
                            }
                            break
                        case 1:
                            obj = {
                                color: '#01BD8B',
                                name: this.$t('Assets.Status2')
                            }
                            break
                        case -1:
                            obj = {
                                color: '#FB474E',
                                name: this.$t('Assets.Status3')
                            }
                            break
                    }
                    
                    return obj
                }
            }
        },
        methods: {
            copy(text) {
                let input = document.createElement("input")
                input.value = text
                document.body.appendChild(input)
                input.select()
                input.setSelectionRange(0, input.value.length)
                document.execCommand('Copy')
                document.body.removeChild(input)
                this.$message({
                    message: this.$t('Common.CopySuccessfully'),
                    type: 'success',
                    duration: 1500
                })
            },
            
            getAddress() {
                this.$api.get_address({
                    coin: this.form.user_credit_type
                }).then(res => {
                    this.address = res.data.address || ''
                })
            },
            
            getNotice() {
                this.$api.setting_lang({
                    slug: 'recharge_info'
                },300000).then(res => {
                    if(res.data) {
                        this.notice = res.data
                    }
                })
            },
            
            change() {
                this.getAddress()
            },
            
            getRecordList(bool=false) {
                this.$api.recharge_list({
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            },
            
            handleUpload(res) {
                this.form.image = res.data.file
            },
            
            recharge() {
                this.$refs.form.validate((valid) => {
                    // console.log(valid)
                    if(valid) {
                        if(!this.form.image) {
                            this.$message({
                                message: this.$t('Assets.UploadPaymentVoucher'),
                                type: 'warning',
                                duration: 2500
                            })
                            return
                        }
                        this.loading = true
                        this.$api.recharge(this.form).then(res => {
                            this.loading = false
                            this.$refs.form.resetFields()
                            this.form.image = ''
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                            this.getRecordList(true)
                        }).catch(err => {
                            // console.log(err)
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        background-color: #f6f6f6;
        
        .main {
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 1.2rem 2rem;
            
            .box1 {
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.2rem;
                margin-bottom: 1.2rem;
                background-color: #FFFFFF;
                
                .info-wrap {
                    width: 100%;
                    
                    .info-item {
                        width: 100%;
                        cursor: default;
                        
                        &:not(:last-child) {
                            margin-bottom: 1.6rem;
                        }
                        
                        .label {
                            width: 100%;
                            margin-bottom: 0.8rem;
                            font-size: 1.2rem;
                            color: #6D6F7E;
                            word-wrap: break-word;
                        }
                        
                        /deep/ .el-select {
                            width: 100%;
                        }
                        
                        /deep/ .el-input__inner {
                            height: 4.2rem;
                            line-height: 4.2rem;
                            padding: 0 3.4rem 0 1.6rem;
                            border: 0;
                            font-size: 1.2rem;
                            color: #000000;
                            background-color: #F6F7FA;
                        }
                        
                        .value {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 4.2rem;
                            box-sizing: border-box;
                            padding: 0 1.6rem;
                            border-radius: 4px;
                            background-color: #F6F7FA;
                            
                            .address {
                                width: 100%;
                                font-size: 1.2rem;
                                color: #000000;
                                word-break: break-all;
                            }
                            
                            .copy {
                                margin-left: 1.6rem;
                                font-size: 1.2rem;
                                color: #F08834;
                                white-space: nowrap;
                                cursor: pointer;
                            }
                        }
                        
                        /deep/ .el-form-item {
                            width: 100%;
                            margin-bottom: 0;
                        }
                        
                        .form-input {
                            width: 100%;
                            
                            /deep/ .el-input__inner {
                                height: 4.2rem;
                                line-height: 4.2rem;
                                padding: 0 1.6rem;
                                border: 0;
                                border-radius: 4px;
                                font-size: 1.2rem;
                                color: #000000;
                                background-color: #F6F7FA;
                            }
                        }
                        
                        .upload-box {
                            width: 100%;
                            
                            .upload {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 200px;
                                height: 200px;
                                border-radius: 4px;
                                cursor: pointer;
                                background-color: #f6f6f6;
                                
                                .el-icon-picture-outline {
                                    font-size: 56px;
                                    // font-weight: bold;
                                    color: #999999;
                                }
                            }
                            
                            .upload-img {
                                display: block;
                                width: 200px;
                            }
                        }
                    }
                    
                    .ewm-img {
                        display: flex;
                        justify-content: center;
                        margin: 32px auto;
                    }
                    
                    .confirm-btn {
                        width: 100%;
                        height: 4.6rem;
                        box-sizing: border-box;
                        margin: 1.6rem 0;
                        border: 0;
                        border-radius: 4px;
                        font-size: 1.3rem;
                        color: #FFFFFF;
                        text-align: center;
                        background-color: #F08834;
                    }
                    
                    .notice {
                        width: 100%;
                        
                        .label {
                            width: 100%;
                            margin-bottom: 0.8rem;
                            font-size: 1.2rem;
                            color: #6D6F7E;
                            word-wrap: break-word;
                        }
                        
                        .content {
                            width: 100%;
                            word-wrap: break-word;
                        }
                    }
                }
            }
            
            .box2 {
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.2rem;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 1.6rem;
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }
                
                /deep/ .el-table th.is-leaf {
                    padding: 6px 0;
                    background-color: #ECEEF8;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 4px;
                        font-size: 12px;
                        font-weight: normal;
                        color: #6D6F7E;
                        text-align: center;
                    }
                }
                
                /deep/ .el-table td {
                    padding: 8px 0;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 4px;
                        font-size: 12px;
                        color: #000000;
                        text-align: center;
                    }
                }
                
                /deep/ .el-table__empty-block {
                    min-height: 100px;
                
                    .el-table__empty-text {
                        line-height: 100px;
                        font-size: 13px;
                    }
                }
                
                /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
                    background-color: #F3F5FF;
                }
                
                .pagination-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 1.6rem 0 0;
                    
                    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                        background-color: #F08834;
                    }
                    
                    /deep/ .el-pager li:not(.active):hover {
                        color: #F08834;
                    }
                }
            }
        }
    }
</style>